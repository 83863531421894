/*
 * @Author: 徐少杰 xushaojie@yihuosoft.com
 * @Date: 2025-03-03 14:08:44
 * @LastEditors: 徐少杰 xushaojie@yihuosoft.com
 * @LastEditTime: 2025-03-06 15:24:55
 * @FilePath: \cz-account-web\src\api\wareengineApi.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// import { axios } from '../utils/request'
import { axios } from '../utils/request_new'

/* 企业中心-获取应用包 */
export function getAppPackages(params) {
  return axios({
    url: '/ware-engine/app-packages',
    method: 'get',
    params,
  })
}

/* 企业中心-创建应用包 */
export function createAppPackages(data) {
  return axios({
    url: '/ware-engine/app-packages',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
}

/* 企业中心-更新应用包 */
export function updateAppPackages(data, id) {
  return axios({
    url: `/ware-engine/app-packages/${id}`,
    method: 'put',
    data,
  })
}

/* 企业中心-删除应用包 */
export function deleteAppPackages(id) {
  return axios({
    url: `/ware-engine/app-packages/${id}`,
    method: 'DELETE',
  })
}

/* 获取所有应用包 */
export function getAllAppPackages() {
  return axios({
    url: '/ware-engine/app-packages/all',
    method: 'get',
  })
}

/* 获取所有应用 */
export function getAllApps() {
  return axios({
    url: '/ware-engine/apps/all',
    method: 'get',
    // params: { type: 'managed' },
    params: { type: 'tenant' },
  })
}

/* 更具业务键获取所有应用角色 */
export function getAppRolesByBusinessKey(businessKey) {
  return axios({
    url: '/ware-engine/roles/all',
    method: 'get',
    params: { businessKey },
  })
}

/* 创建应用角色 */
export function createAppRoles(data) {
  return axios({
    url: `/ware-engine/apps/${data.appId}/roles`,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

/* 批量创建应用角色 */
export function batchCreateAppRoles(data) {
  return axios({
    url: `/ware-engine/apps/roles/batch`,
    method: 'post',
    data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

/* 删除应用角色 */
export function deleteAppRoles(data) {
  return axios({
    url: `/ware-engine/apps/${data.appId}/roles/${data.roleId}`,
    method: 'DELETE',
  })
}

/* 批量移除应用角色 */
export function batchDeleteAppRoles(data) {
  return axios({
    url: `/ware-engine/apps/roles/batch`,
    method: 'DELETE',
    data,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}


export const wareengineApi = {
  getAppPackages,
  createAppPackages,
  updateAppPackages,
  deleteAppPackages,
  getAllAppPackages,
  getAllApps,
  getAppRolesByBusinessKey,
  createAppRoles,
  deleteAppRoles,
  batchCreateAppRoles,
  batchDeleteAppRoles
}
